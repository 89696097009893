<template>
  <div>
    <div class="card-toolbar mb-5">
<!--      <router-link v-if="$can('general_debit.create')" to="/sales/general-debits/create" class="btn btn-primary font-weight-bolder">-->
<!--        <v-icon>mdi-plus</v-icon>-->
<!--        {{ $t('debits.add_sales_debits_general') }}-->
<!--      </router-link>-->
      <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5">
        <span><i class="la la-filter"></i> <span>{{ $t('advanced_filter') }}</span></span>
      </button>
    </div>

    <div class="card card-custom mb-5" v-if="showAdvancedSearch">
      <div class="card-body">
        <div class="m-form m-form--fit m--margin-bottom-20" v-if="showAdvancedSearch">
          <div class="row mb-5">

            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="customer_id">{{ $t('debits.customer') }}</label>
              <multiselect
                  id="customer_id"
                  v-model="customer"
                  :placeholder="$t('debits.customer')"
                  label="fullname"
                  track-by="id"
                  :options="customers"
                  :multiple="false"
                  :taggable="false"
                  :show-labels="false"
                  :show-no-options="false"
                  :show-no-results="false"
                  @search-change="getCustomers($event)">
              </multiselect>
            </div>

            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="invoice_code">{{ $t('debits.debit_code') }}</label>
              <input v-model="filters.invoice_code" type="text" id="invoice_code" class="form-control">
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="from_date">{{ $t('debits.from_date') }}</label>
              <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="to_date">{{ $t('debits.to_date') }}</label>
              <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label>{{ $t('debits.currency') }}</label>
              <select name="" id="currency_id" v-model="filters.currency_id" class="custom-select">
                <option v-for="row in currencies" :value="row.id" :key="row.id">
                  {{ row.name }}
                </option>
              </select>
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label>{{ $t('debits.added_by') }}</label>
              <multiselect
                  v-model="user"
                  :placeholder="$t('debits.added_by')"
                  label="name"
                  track-by="id"
                  :options="users"
                  :multiple="false"
                  :taggable="false"
                  :show-labels="false"
                  :show-no-options="false"
                  :show-no-results="false"
                  @search-change="getUsers($event)">
              </multiselect>
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="from_quantity">{{ $t('debits.from_quantity') }}</label>
              <input v-model="filters.from_quantity" type="number" id="from_quantity" class="form-control">
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="to_quantity">{{ $t('debits.to_quantity') }}</label>
              <input v-model="filters.to_quantity" type="number" id="to_quantity" class="form-control">
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="f_status">{{ $t('status') }}</label>
              <select name="" id="f_status" v-model="filters.status" type="text" class="custom-select">
                <option value="">{{ $t('all') }}</option>
                <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.title }}</option>
              </select>
            </div>


            <div class="form-group d-inline-flex col-md-6 mt-10">
              <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                              <span><i class="fas fa-search"></i>
                                  <span>{{ $t('search') }}</span>
                              </span>
              </button>
              <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="m_reset">
                              <span><i class="fas fa-trash-restore"></i>
                                  <span>{{ $t('reset_search') }}</span>
                              </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card card-custom">
      <div class="card-body">
        <!-- <div>

        </div> -->
        <!-- End Filter -->
        <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">
          <template slot="#" slot-scope="props">
            <span>{{props.index}}</span>
          </template>
          <template slot="status" slot-scope="props">
            <button v-if="$can('general_debit.change_status')" @click="changeStatus(props.row.id, props.row.status)" class="btn btn-secondary m-btn m-btn--icon w-auto">
              {{ $t('change_status') }} ({{ props.row.status_name }})
            </button>
            <button v-else class="btn btn-secondary m-btn m-btn--icon w-auto">
              {{ $t('change_status') }} ({{ props.row.status_name }})
            </button>
          </template>
          <template slot="actions" slot-scope="props">

            <!--               -->
            <v-btn icon color="pink" v-b-tooltip.hover :title="$t('edit')" v-if="$can('general_debit.update')" :to="`/sales/general-debits/edit/${props.row.id}`">
              <v-icon small class="mr-2 text-info">mdi-pencil</v-icon>
            </v-btn>
            <!--               -->
            <v-icon small class="text-danger" v-b-tooltip.hover :title="$t('delete')" v-if="$can('general_debit.delete')" @click="deleteItem(props.row)">mdi-delete</v-icon>

            <b-dropdown :id="'dropdown-offset_'+props.row.id" :text="$t('more_actions') " variant="outline-primary" class="m-2">
<!--              <router-link class="dropdown-item" v-if="$can('general_debit.payment_sales_invoices') && props.row.status == 2" :to="`/sales/payment_sales_invoices/create/${props.row.id}/debits`">-->
<!--                {{ $t('MENU.payment_sales_debits') }}-->
<!--              </router-link>-->

              <div class="dropdown-item d-flex justify-content-between" v-if="$can('general_debit.pdf_print_tax_invoice')">
                <label>{{ $t('tax_invoice_printing') }}</label>
                <div class="d-flex justify-content-between">
                  <router-link target="_blank" class="ml-3 mr-3" v-b-tooltip.hover :title="$t('print')" :to="`/print/general-debits-tax-invoice/${props.row.id}/print`">
                    <i class="fas fa-print"></i>
                  </router-link>
                  <router-link target="_blank" class="ml-3 mr-3" v-b-tooltip.hover :title="$t('pdf')" :to="`/print/general-debits-tax-invoice/${props.row.id}/pdf`">
                    <i class="fas fa-file-pdf"></i>
                  </router-link>
                </div>
              </div>
<!--              <div class="dropdown-item d-flex justify-content-between" v-if="$can('general_debit.printing_export_pdf')">-->
<!--                <label>{{ $t('MENU.invoice_printing_80_mm') }}</label>-->
<!--                <div class="d-flex justify-content-between">-->
<!--                  <router-link target="_blank" class="ml-3 mr-3" v-b-tooltip.hover :title="$t('print')" :to="`/print/general-sales-debit/${props.row.id}/print`">-->
<!--                    <i class="fas fa-print"></i>-->
<!--                  </router-link>-->
<!--                  <router-link target="_blank" class="ml-3 mr-3" v-b-tooltip.hover :title="$t('pdf')" :to="`/print/general-sales-debit/${props.row.id}/pdf`">-->
<!--                    <i class="fas fa-file-pdf"></i>-->
<!--                  </router-link>-->
<!--                </div>-->
<!--              </div>-->





<!--              <router-link v-if="$can('general_debit.printing_export_pdf')" target="_blank" class="dropdown-item" :to="`/print/general-sales-debit/${props.row.id}/print`">-->
<!--                {{ $t('MENU.invoice_printing') }}-->
<!--              </router-link>-->
<!--&lt;!&ndash;              <router-link v-if="$can('general_debit.printing_export_pdf')" target="_blank" class="dropdown-item" :to="`/print/general-sales-debit/${props.row.id}/pdf`">&ndash;&gt;-->
<!--&lt;!&ndash;                {{ $t('export_invoice_pdf') }}&ndash;&gt;-->
<!--&lt;!&ndash;              </router-link>&ndash;&gt;-->

<!--              <router-link target="_blank" v-if="$can('general_debit.pdf_print_tax_invoice')" class="dropdown-item" :to="`/print/general-debits-tax-invoice/${props.row.id}/print`">-->
<!--                {{ $t('tax_invoice_printing') }}-->
<!--              </router-link>-->
<!--&lt;!&ndash;              <router-link target="_blank" v-if="$can('general_debit.pdf_print_tax_invoice')" class="dropdown-item" :to="`/print/general-debits-tax-invoice/${props.row.id}/pdf`">&ndash;&gt;-->
<!--&lt;!&ndash;                {{ $t('export_tax_invoice_pdf') }}&ndash;&gt;-->
<!--&lt;!&ndash;              </router-link>&ndash;&gt;-->

<!--              <router-link target="_blank" v-if="$can('general_debit.pdf_print_tax_invoice_simplified')" class="dropdown-item" :to="`/print/general-debits-tax-invoice/${props.row.id}/print/simplified`">-->
<!--                {{ $t('tax_invoice_printing_simplified') }}-->
<!--              </router-link>-->
<!--&lt;!&ndash;              <router-link target="_blank" v-if="$can('general_debit.pdf_print_tax_invoice_simplified')" class="dropdown-item" :to="`/print/general-debits-tax-invoice/${props.row.id}/pdf/simplified`">&ndash;&gt;-->
<!--&lt;!&ndash;                {{ $t('export_tax_invoice_pdf_simplified') }}&ndash;&gt;-->
<!--&lt;!&ndash;              </router-link>&ndash;&gt;-->


            </b-dropdown>
          </template>
        </v-server-table>

      </div>
    </div>
    <!--end::customer-->
    <b-modal ref="status-modal" size="md" hide-footer :title="$t('change_status')">
      <change-status-form @hide-modal="hideModal()"
                          :status-list="status_list"
                          :url="routeChangeStatus"
                          :current-id="innerId"
                          :current-status="statusId"
                          @handling-data="getDataAfterChangeStatus">
      </change-status-form>
    </b-modal>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import ChangeStatusFrom from "@/view/content/forms/ChangeStatusForm";


export default {
  name: "index-debits",
  components: {'change-status-form': ChangeStatusFrom,},
  data() {
    return {
      mainRoute: 'sales/sales_debits',
      routeChangeStatus: 'sales/sales_debit/change-status',
      mainRouteDependency: 'base/dependency',

      showAdvancedSearch: false,

      filter: {
        sortBy: 'id',
      },
      filters: {
        customer_id: null,
        debit_code: null,
        from_date: null,
        to_date: null,
        currency_id: null,
        user_id: null,
        status: null,
        from_quantity: null,
        to_quantity: null,
        is_general: 1,
      },
      status_list: [],

      // status_list: [
      //     {id: 1, text: this.$t('active')},
      //     {id: 0, text: this.$t('inactive')},
      // ],
      columns: ['#','customer_name', 'create_date', 'debit_code', 'total', 'total_paid_amount', 'currency_name', 'is_paid_status_name', 'status', 'actions'],
      data: [],
      currencies: [],
      customers: [],
      users: [],
      innerId: null,
      statusId: null,
      customer: null,
      user: null,

    }
  },
  computed: {
    options: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          customer_name: that.$t('debits.customer'),
          create_date: that.$t('debits.create_date'),
          debit_code: that.$t('debits.debit_code'),
          total: that.$t('debits.total'),
          total_paid_amount: that.$t('paid_amount'),
          currency_name: that.$t('debits.currency'),
          is_paid_status_name: that.$t('sales_invoices.payment_status'),
          status: that.$t('status'),
          actions: that.$t('actions'),
        },
        sortable: ['name'],
        filterByColumn: false,
        filterable: false,
        customFilters: [{
          name: 'alphabet',

        }],
        orderBy: {'column': 'id'},

        alwaysShowPerPageSelect: false,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [5, 10, 15, 25],

        requestFunction(data) {
          let _params = {
            ascending: data.ascending,
            byColumn: data.byColumn,
            perPage: data.limit,
            orderBy: data.orderBy,
            page: data.page,
            filter: data.query,
            ...that.filters,
          }
          return ApiService.query(`${that.mainRoute}`, {..._params});

        },
        responseAdapter(resp) {
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        }
      }

    },
  },
  watch: {
    user: function (val) {
      if (val) {
        this.filters.user_id = val.id;
      } else {
        this.filters.user_id = null;
      }
    },
    customer: function (val) {
      if (val) {
        this.filters.customer_id = val.id;
      } else {
        this.filters.customer_id = null;
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.general_sales_debits_management")}]);
    this.getCurrencies();
    // this.getCustomers();
    // this.getUsers();
    this.getStatus();
  },
  methods: {
    changeStatus(id, status) {
      this.innerId = id;
      this.statusId = status;
      this.showModal();
    },
    openAdvancedSearch() {
      this.showAdvancedSearch = !this.showAdvancedSearch;
    },
    doFilter() {
      this.$refs.table.refresh();
    },
    resetFilter() {
      this.filters.customer_id = null;
      this.filters.estimate_code = null;
      this.filters.from_date = null;
      this.filters.to_date = null;
      this.filters.currency_id = null;
      this.filters.user_id = null;
      this.filters.status = null;
      this.filters.from_quantity = null;
      this.filters.to_quantity = null;
      this.user = null;
      this.customer = null;

      this.$refs.table.refresh();
    },
    getFetch() {
      this.$refs.table.refresh();
    },

    deleteItem(item) {
      this.$confirmAlert('', this.actionDelete, item);
    },
    getStatus() {
      ApiService.get(this.mainRouteDependency + "/status", {params: {type: 'debits'}}).then((response) => {
        this.status_list = response.data.data;
      });
    },
    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },
    getCustomers(filter) {
      if (filter && filter.length >= 3) {
        ApiService.get(`${this.mainRouteDependency}/customers`, {params: {filter: filter}}).then((response) => {
          this.customers = response.data.data;
        });
      } else {
        this.customers = [];
      }
    },
    getUsers(filter) {
      if (filter && filter.length >= 3) {
        ApiService.get(`${this.mainRouteDependency}/users`, {params: {filter: filter}}).then((response) => {
          this.users = response.data.data;
        });
      }
    },

    actionDelete(item) {
      ApiService.delete(`${this.mainRoute}/${item.id}`).then((response) => {
        this.getFetch();
        this.$successAlert(response.data.message)
      }).catch((error) => {
        this.$errorAlert(error)
      })
    },
    showModal() {
      this.$refs['status-modal'].show()
    },
    hideModal() {
      this.$refs['status-modal'].hide();
    },
    getDataAfterChangeStatus() {
      this.innerId = null;
      this.statusId = null;
      this.doFilter();
    },
  },
};
</script>
